<template>
	<Metier name="comptabilite-controle-de-gestion" title="Comptabilité – Contrôle de Gestion" :main-menu="[{title: 'Comptable ', missions: ['Gère la comptabilité en rapport avec les fournisseurs (factures, paiements…)', 'Gère la comptabilité en rapport avec les clients de la plateforme (refacturations externes…)', 'Gère la comptabilité générale de la plateforme (contrôle des comptes, enregistrement des opérations…)'], competences: ['Formation en comptabilité', 'Connaissances des techniques de comptabilité', 'Maîtrise des outils informatiques'], qualites: ['Sens du contact', 'Rigueur', 'Esprit d\'analyse']}, {title: 'Contrôleur de gestion', missions: ['Participe à l\'élaboration du programme budgétaire de la plateforme', 'Gère les budgets du domaine dont il est en charge (maintenance, investissement…)', 'Fournit des estimations et analyses de budget'], competences: ['Formation en gestion ou comptabilité', 'Maîtrise des outils informatiques'], qualites: ['Rigueur', 'Organisation', 'Esprit d\'analyse', 'Sens du contact']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
